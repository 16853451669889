import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function SMS() {
  const [mobile, setMobile] = React.useState("");
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <TextField
        label="Mobile number"
        value={mobile}
        onChange={(e) => {
          setMobile(e.target.value);
        }}
      />
      <Button variant="contained" color="primary">
        Save
      </Button>
    </Stack>
  );
}
