import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Container,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ResetAndConfirmation from "../../../../components/ResetAndConfirmation";
import { useAppDispatch } from "../../../../utils/hooks";
import { updateSecrets } from "./SecretsSlice";

const initialState = {
  firstName: true,
  lastName: true,
  birthday: true,
  id: false,
  sex: false,
};

const initialPswd = {
  password: "",
  passwordConfirm: "",
  showPassword: false,
};

export default function SecretsStep({
  handleComplete,
}: {
  handleComplete: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [state, setState] = React.useState(initialState);
  const [pswd, setPswd] = React.useState(initialPswd);

  useEffect(() => {
    dispatch(
      updateSecrets({
        decryptInformation: state,
        password: pswd.password,
      })
    );
  }, [state, pswd]);

  const valid = (): boolean => {
    if (pswd.password !== pswd.passwordConfirm) {
      return false;
    }
    handleComplete();
    return true;
  };

  const onReset = (): void => {
    setState(initialState);
    setPswd(initialPswd);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { firstName, lastName, birthday, id, sex } = state;

  return (
    <Container maxWidth="sm" component={Paper}>
      <ResetAndConfirmation onReset={onReset} valid={valid} />
      <Box>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">
            What information will be required to decrypt your data?
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={firstName}
                  onChange={handleChange}
                  name="firstName"
                />
              }
              label="First Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={lastName}
                  onChange={handleChange}
                  name="lastName"
                />
              }
              label="Last Name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={birthday}
                  onChange={handleChange}
                  name="birthday"
                />
              }
              label="Birthday"
            />
            <FormControlLabel
              control={
                <Checkbox checked={id} onChange={handleChange} name="id" />
              }
              label="ID"
            />
            <FormControlLabel
              control={
                <Checkbox checked={sex} onChange={handleChange} name="sex" />
              }
              label="Sex"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Stack>
        <FormControl sx={{ ml: 3, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {t("password")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={pswd.showPassword ? "text" : "password"}
            value={pswd.password}
            onChange={(e) => {
              setPswd({
                ...pswd,
                password: e.target.value,
              });
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPswd({
                      ...pswd,
                      showPassword: !pswd.showPassword,
                    });
                  }}
                  onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.preventDefault();
                  }}
                  edge="end"
                >
                  {!pswd.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl sx={{ m: 3, width: "25ch" }} variant="outlined">
          <InputLabel htmlFor="password-confirm">{t("Confirm")}</InputLabel>
          <OutlinedInput
            id="password-confirm"
            type="password"
            value={pswd.passwordConfirm}
            onChange={(e) => {
              setPswd({
                ...pswd,
                passwordConfirm: e.target.value,
              });
            }}
            error={pswd.password !== pswd.passwordConfirm}
            label="Confirmation"
          />
        </FormControl>
      </Stack>
    </Container>
  );
}
