import { Button, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { decrypt } from "../../utils/encrypt";

export default function Decrypt({ data }: { data: string }) {
  const [password, setPassword] = React.useState("");
  const [decrypted, setDecrypted] = React.useState("");

  const handleDecrypt = () => {
    const { ciphertext, iv } = JSON.parse(data);
    decrypt(password, ciphertext, iv)
      .then((msg) => {
        setDecrypted(msg);
      })
      .catch((err) => {
        setDecrypted("Decryption failed");
      });
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <TextField
          id="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="secondary" onClick={handleDecrypt}>
          Decrypt
        </Button>
      </Stack>
      <Typography>{decrypted}</Typography>
    </>
  );
}
