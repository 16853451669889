import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import * as React from "react";
import CloudFile from "./CloudFile";
import Decrypt from "./Decrypt";
import UploadFile from "./UploadFile";

export default function DecryptPage() {
  const [encrypted, setEncrypted] = React.useState("");
  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <UploadFile setData={setEncrypted} />
        </Grid>
        <Grid item sm={2}>
          <Divider orientation="vertical" variant="fullWidth">
            OR
          </Divider>
        </Grid>
        <Grid item sm={5}>
          <CloudFile setData={setEncrypted} />
        </Grid>
        <Grid item sm={12}>
          {encrypted}
        </Grid>
        <Grid item sm={12}>
          <Divider variant="fullWidth" sx={{ mb: 3 }} />
          <Decrypt data={encrypted} />
        </Grid>
      </Grid>
    </Container>
  );
}
