import { Divider, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function Email() {
  const [email, setEmail] = React.useState("");
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <TextField
          label="Your Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Stack>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <TextField label="Emergency Contactor Email" />
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Stack>
    </>
  );
}
