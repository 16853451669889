import { Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function IOS() {
  const [bark, setBark] = React.useState("");

  const handleTest = () => {
    fetch(
      "https://api.day.app/CXzybDCr3brTiCE5PriLrn/Digital Legacy Notifications/Test"
    );
  };
  return (
    <>
      <Typography variant="h6" color="initial" sx={{ pb: 3 }}>
        Please download{" "}
        {
          <Link href="https://apps.apple.com/us/app/bark-customed-notifications/id1403753865">
            Bark
          </Link>
        }{" "}
        for iOS notifications
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <TextField
          label="Bark key"
          value={bark}
          onChange={(e) => {
            setBark(e.target.value);
          }}
        />
        <Button variant="contained" color="primary">
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={handleTest}>
          Test
        </Button>
      </Stack>
    </>
  );
}
