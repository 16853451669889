import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import * as React from "react";

interface ResetAndConfirmationProps {
  onReset: () => void;
  valid: () => boolean;
}

export default function ResetAndConfirmation(props: ResetAndConfirmationProps) {
  const { onReset, valid } = props;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
      <Button onClick={onReset}>Reset</Button>
      <Button
        onClick={() => {
          if (!valid()) {
            enqueueSnackbar("Parameters are not valid", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Step finished", {
              variant: "success",
            });
          }
        }}
      >
        Complete
      </Button>
    </Box>
  );
}
