import { Button } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function IndexPage() {
  const { t } = useTranslation();

  return (
    <>
      <img
        src={process.env.PUBLIC_URL + "index.jpg"}
        alt=""
        style={{ position: "absolute", width: "100%", top: 60 }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Button
          component={Link}
          to="/create"
          variant="contained"
          color="inherit"
        >
          {t("Create your digital legacy")}
        </Button>
      </div>
    </>
  );
}
