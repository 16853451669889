import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useAppDispatch } from "../../../../utils/hooks";
import * as React from "react";
import { updateBasicInfo } from "./BasicInfoSlice";
import { useTranslation } from "react-i18next";

interface BasicInfoProps {
  firstName: string;
  lastName: string;
  sex: string;
  age: number;
  id: string;
  birthday: string;
}

export default function BasicInfo() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [data, setData] = React.useState<BasicInfoProps>({
    firstName: "",
    lastName: "",
    sex: "",
    age: 0,
    id: "",
    birthday: "",
  });

  React.useEffect(() => {
    dispatch(updateBasicInfo(data));
  }, [data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <TextField
          label="First Name"
          value={data.firstName}
          name="firstName"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Last Name"
          value={data.lastName}
          name="lastName"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          sx={{ minWidth: 150 }}
          select
          label="Sex"
          value={data.sex}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setData({
              ...data,
              sex: event.target.value,
            });
          }}
        >
          <MenuItem value="male">{t("Male")}</MenuItem>
          <MenuItem value="female">{t("Female")}</MenuItem>
          <MenuItem value="other">{t("Other")}</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="Age"
          type="number"
          value={data.age}
          name="age"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          label="ID"
          value={data.id}
          name="id"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="BirthDay"
            value={data.birthday}
            onChange={(newValue) => {
              setData({
                ...data,
                birthday: newValue || "",
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
