import {
  Button,
  Container,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import { encrypt } from "../../../../utils/encrypt";

export default function StorageStep({
  handleComplete,
}: {
  handleComplete: () => void;
}) {
  const store = useStore();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState("");

  const downloadData = (data: object) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "digital-legacy.json";

    link.click();
  };

  async function generateEncryptedData() {
    const password = store.getState().secrets.password;
    const state = { ...store.getState() };
    delete state.secrets;
    const msg = JSON.stringify(state);
    const res = await encrypt(password, msg);
    return res;
  }

  const handleDownloadArchive = () => {
    generateEncryptedData().then((data) => {
      downloadData(data);
    });
  };

  const handleUpload = (key: string) => {
    generateEncryptedData().then((data) => {
      fetch(process.env.REACT_APP_CF_URL as string, {
        method: "POST",
        body: JSON.stringify({
          key,
          value: JSON.stringify(data),
        }),
      })
        .then((res) => {
          if (res.ok) {
            enqueueSnackbar("Successfully uploaded", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Failed to upload", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Failed to upload", {
            variant: "error",
          });
        });
    });
  };

  return (
    <Container maxWidth="md" component={Paper} sx={{ padding: 5 }}>
      <Stack direction="row" divider={<Divider orientation="vertical" />}>
        <Box sx={{ width: "50%" }} justifyContent="center">
          <Stack spacing={5} sx={{ width: "50%", margin: "auto" }}>
            <Typography align="center" variant="h5">
              {t("Local")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadArchive}
            >
              Download Encrypted File
            </Button>
          </Stack>
        </Box>

        <Box sx={{ width: "50%" }}>
          <Stack spacing={5} sx={{ width: "50%", margin: "auto" }}>
            <Typography align="center" variant="h5">
              Cloud
            </Typography>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpload(email)}
            >
              Upload
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}
