import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  info: {},
};

export const BasicInfo = createSlice({
  name: "basic",
  initialState,
  reducers: {
    updateBasicInfo: (state, action: PayloadAction<object>) => {
      // @ts-ignore
      state.info = action.payload;
    },
  },
});

export const { updateBasicInfo } = BasicInfo.actions;

export default BasicInfo.reducer;
