import { configureStore } from "@reduxjs/toolkit";
import BasicInfoReducer from "../pages/create/steps/edit/BasicInfoSlice";
import VirtualAssetsReducer from "../pages/create/steps/edit/VirtualAssetsSlice";
import RealAssetsReducer from "../pages/create/steps/edit/RealAssetsSlice";
import SecretsReducer from "../pages/create/steps/secrets/SecretsSlice";

const preloadedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState") || "")
  : {};

const store = configureStore({
  reducer: {
    basic: BasicInfoReducer,
    vassets: VirtualAssetsReducer,
    rassets: RealAssetsReducer,
    secrets: SecretsReducer,
  },
  preloadedState,
});

store.subscribe(() => {
  console.log(store.getState());
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
