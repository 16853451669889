import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RAssetsStateProps {
  decryptInformation: object;
  password: string;
}

const initialState: RAssetsStateProps = {
  decryptInformation: {},
  password: "",
};

export const SecretsSlice = createSlice({
  name: "secrets",
  initialState,
  reducers: {
    updateSecrets: (state, action: PayloadAction<RAssetsStateProps>) => {
      // @ts-ignore
      state.decryptInformation = action.payload.decryptInformation;
      state.password = action.payload.password;
    },
  },
});

export const { updateSecrets } = SecretsSlice.actions;

export default SecretsSlice.reducer;
