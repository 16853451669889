import * as React from "react";
import { useStore } from "react-redux";
import GridEditor from "../../../../components/GridEditor";
import { useAppDispatch } from "../../../../utils/hooks";
import { updateRows } from "./VirtualAssetsSlice";

const defaultRow = {
  assetName: "123",
  userName: "",
  password: "",
  url: "",
  remarks: "",
};

export default function VirtualAssets() {
  // const initRows = useAppSelector((state) => state.vassets.rows);
  const dispatch = useAppDispatch();
  const store = useStore();

  const columns = React.useMemo(
    () => [
      {
        Header: "Asset Name",
        accessor: "assetName",
      },
      {
        Header: "Username",
        accessor: "userName",
      },
      {
        Header: "Password",
        accessor: "password",
      },
      {
        Header: "URL",
        accessor: "url",
      },
      {
        Header: "Remarks",
        accessor: "remarks",
      },
    ],
    []
  );
  return (
    <GridEditor
      columns={columns}
      defaultRow={defaultRow}
      updateRedux={(data: any) => {
        dispatch(updateRows(data));
      }}
      initData={store.getState().vassets.rows}
    />
  );
}
