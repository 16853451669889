import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../../../utils/hooks";
import BasicInfo from "./BasicInfo";
import Others from "./Others";
import RealAssets from "./RealAssets";
import VirtualAssets from "./VirtualAssets";

export default function EditStep({
  handleComplete,
}: {
  handleComplete: () => void;
}) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useLocalStorage("expanded", "basicInfo");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container maxWidth="lg">
      <Paper sx={{ padding: 2 }} elevation={3}>
        <Accordion
          expanded={expanded === "basicInfo"}
          onChange={handleChange("basicInfo")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("Basic Information")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {t("Your Identifiactions")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BasicInfo />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "vassets"}
          onChange={handleChange("vassets")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("Virtual Assets")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {t("Your social network accounts, gaming accounts and etc")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <VirtualAssets />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "rassets"}
          onChange={handleChange("rassets")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("Real-World Assets")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {t("Your bank accounts, credit cards, insurances and etc")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RealAssets />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "others"}
          onChange={handleChange("others")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {t("Others")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {t("Other stuff that you want to add to your Digital Legacy")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Others />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Container>
  );
}
