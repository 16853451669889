import { GridRowsProp } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface VAssetsStateProps {
  rows: GridRowsProp[];
}

// Define the initial state using that type
const initialState: VAssetsStateProps = {
  rows: [],
};

export const counterSlice = createSlice({
  name: "vassets",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateRows: (state, action: PayloadAction<GridRowsProp[]>) => {
      // @ts-ignore
      state.rows = action.payload;
    },
  },
});

export const { updateRows } = counterSlice.actions;

export default counterSlice.reducer;
