/* eslint-disable react/prop-types */
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import React from "react";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SearchIcon />
      <InputBase
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

export default GlobalFilter;
