import { Button, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";

export default function CloudFile({
  setData,
}: {
  setData: (data: string) => void;
}) {
  const [email, setEmail] = React.useState("");

  const handleRetrive = () => {
    fetch(process.env.REACT_APP_CF_URL + `/?key=${email}`).then((res) => {
      res.text().then((data) => {
        // console.log(data);
        setData(data);
      });
    });
  };

  return (
    <Stack spacing={5} sx={{ width: "50%", margin: "auto" }}>
      <Typography align="center" variant="h5">
        Cloud
      </Typography>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleRetrive}>
        Retrive
      </Button>
    </Stack>
  );
}
