import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import * as React from "react";

const Input = styled("input")({
  display: "none",
});

export default function UploadFields({
  setData,
}: {
  setData: (data: any) => void;
}) {
  const [file, setFile] = React.useState<File | null>(null);
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label htmlFor="file-input">
          <Input
            accept="text/*,.json,.*"
            id="file-input"
            type="file"
            onChange={() => {
              // @ts-ignore
              setFile(document.getElementById("file-input")?.files[0]);
              // @ts-ignore
              const file = document.getElementById("file-input")?.files[0];
              const reader = new FileReader();

              reader.addEventListener(
                "load",
                () => {
                  console.log(reader.result);
                  setData(reader.result);
                },
                false
              );
              if (file) {
                reader.readAsText(file);
              }
            }}
          />
          <Button variant="contained" component="span" color="primary">
            Select Encrypted File
          </Button>
        </label>
      </div>
      <Typography align="center">
        {file && "Selected file: " + file.name}
      </Typography>
    </>
  );
}
