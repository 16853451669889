import * as React from "react";
import { Routes, Route } from "react-router-dom";
import IndexPage from "./pages/index";
import CreatePage from "./pages/create/index";
import DecryptPage from "./pages/decrypt/index";
import AppBar from "./pages/Appbar";

export default function RouterPage() {
  return (
    <>
      <AppBar />
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="create" element={<CreatePage />} />
        <Route path="decrypt" element={<DecryptPage />} />
      </Routes>
    </>
  );
}
