import { GridRowsProp } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RAssetsStateProps {
  rows: GridRowsProp[];
}

const initialState: RAssetsStateProps = {
  rows: [],
};

export const RAssets = createSlice({
  name: "rassets",
  initialState,
  reducers: {
    updateRows: (state, action: PayloadAction<GridRowsProp[]>) => {
      // @ts-ignore
      state.rows = action.payload;
    },
  },
});

export const { updateRows } = RAssets.actions;

export default RAssets.reducer;
