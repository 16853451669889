import React, { useEffect } from "react";
import EnhancedTable from "./EnhancedTable";

interface GridEditorProps {
  columns: any;
  defaultRow: any;
  updateRedux: any;
  initData: [];
}
const App = (props: GridEditorProps) => {
  const { columns, defaultRow, updateRedux, initData } = props;

  const [data, setData] = React.useState(initData);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  useEffect(() => {
    updateRedux(data);
  }, [data]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex: number, columnId: any, value: any) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    // @ts-ignore
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            // @ts-ignore
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <EnhancedTable
      columns={columns}
      data={data}
      setData={setData}
      updateMyData={updateMyData}
      skipPageReset={skipPageReset}
      defaultRow={defaultRow}
    />
  );
};

export default App;
